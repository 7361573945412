*{
  margin: 0;
  padding: 0;


}

html, body{
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Estilo geral da barra de rolagem */
::-webkit-scrollbar {
  width: 12px; /* Largura da barra vertical */
  height: 12px; /* Altura da barra horizontal */
}

/* Fundo da barra de rolagem */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo do track */
}

/* Cor do polegar (thumb) da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: #888; /* Cor do thumb */
  border-radius: 6px; /* Arredondamento do thumb */
}

/* Cor do polegar ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor do thumb ao passar o mouse */
}
